import React from "react";
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import logo from "../../assets/images/pplogo.jpg";

function AfhaalPdf(props) {
	return (
		<Document>			
			{props.afhaalpunten.map((afhaalpunt, index) => (				
			<Page style={{padding: '15px 50px 15px 50px', fontWeight: 700}}>
				<View style={{width: '100%', height: 50}} fixed></View>
				<View style={{paddingBottom: 50}}>
					<View style={{width: '100%', justifyContent: 'center'}}>
						<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
					</View>	
					<View style={{flexDirection: 'row', width: '100%', marginTop: 40, justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
						<View>
							<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai Afhaalpunten</Text></View>
							<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.exportInfo.evt}</Text></View>	
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportDate}</Text></View>
						</View>	
						<View style={{alignItems: 'flex-end'}}>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.bedrijfsnaam}</Text></View>	
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.adres}</Text></View>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.postcodeplaats}</Text></View>
						</View>
					</View>			
				</View>					
				<View>
					<Text>{props.afhaalStats[afhaalpunt].naam}</Text>
					<View style={{flexDirection: 'row', paddingHorizontal: 8, color: '#555555', fontSize: 10, marginTop: 10, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
						<View style={{width: '18%'}}>
							<Text>Omschrijving</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>aantal</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>tokens</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>BTW</Text>
						</View>
						<View style={{width: '17%', textAlign: 'right'}}>
							<Text>Eigen gebruik</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>Omzet (incl btw)</Text>
						</View>
					</View>
					{props.afhaalStats[afhaalpunt].items ? 
					<View>
						{Object.entries(props.afhaalStats[afhaalpunt].items).filter(item => item[1].aantal > 0).sort((a, b) => { return b[1].omzet - a[1].omzet }).map((item, index) => (
							<View style={{flexDirection: 'row', fontSize: 10, paddingHorizontal: 8, paddingVertical: 4, justifyContent: 'space-between', backgroundColor: index%2 !== 0 ? null : 'rgba(0,0,0,0.04)'}}>
								<View style={{width: '18%'}}>
									<Text>{item[0]}</Text>
								</View>
								<View style={{width: '15%', textAlign: 'right'}}>
									<Text>{item[1].aantal}</Text>
								</View>
								<View style={{width: '15%', textAlign: 'right'}}>
									<Text>{item[1].tokens}</Text>
								</View>						
								<View style={{width: '15%', textAlign: 'right'}}>
									<Text>€ {(item[1].btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</Text>
								</View>
								<View style={{width: '17%', textAlign: 'right'}}>
									<Text>-€ {(item[1].promotie.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</Text>
								</View>
								<View style={{width: '20%', textAlign: 'right'}}>
									<Text>€ {(item[1].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</Text>
								</View>
							</View>
						))}
					</View>
					:null}
					<View style={{borderTopWidth: 1, borderColor: '#000', borderStyle: 'solid', flexDirection: 'row', fontSize: 11, paddingHorizontal: 8, paddingVertical: 4, justifyContent: 'space-between'}}>
						<View style={{width: '18%'}}>
							<Text>Totaal</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>{props.afhaalStats[afhaalpunt].transacties}</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>{props.afhaalStats[afhaalpunt].tokens}</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>€ {props.afhaalStats[afhaalpunt].btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
						<View style={{width: '17%', textAlign: 'right'}}>
							<Text>-€ {props.afhaalStats[afhaalpunt].promotie.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>€ {props.afhaalStats[afhaalpunt].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
					</View>
				</View>
				<View style={{width: '100%', height: 25}} fixed></View>
			</Page>	
			))}	
		</Document>
	);
}

export default AfhaalPdf;