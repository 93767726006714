import React from "react";
import * as XLSX from "xlsx";
import moment from "moment";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import VerkoopPdf from '../../components/pdf/verkooppdf';

class exportStats extends React.Component {

    render() {return (<div></div>)}
	
	exportCSV = (type, stats, info) => {
		var omzetlockers = 0;
		var baromzethoog = 0;
		var baromzetlaag = 0;
		var baromzet13 = 0;
		var inkomstenonline = 0;			
		var inkomstenoplaadpunten = 0;	
		var serviceKosten = 0;
		var startstr = '';
		var endstr = '';
		Object.keys(stats).sort().forEach((dagstr, ind) => {
            console.log(dagstr);
			var statsDate = stats[dagstr];	
            if(ind === 0){startstr = dagstr};
            if(ind === (Object.keys(stats).length - 1)){endstr = dagstr};						
            if(statsDate.tokenverkoop){
                inkomstenonline = inkomstenonline + statsDate.tokenverkoop.omzet;
            }	
            if(statsDate.kluisverkoop && statsDate.kluisverkoop.aantal){				
                omzetlockers = omzetlockers + statsDate.kluisverkoop.omzet;
                inkomstenonline = inkomstenonline + statsDate.kluisverkoop.omzet;
            }
            if(statsDate.afhaalpunten){				
                Object.keys(statsDate.afhaalpunten).forEach(afhaalpunt => {					
                    Object.keys(statsDate.afhaalpunten[afhaalpunt].items).forEach(item => {
                        if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.09){
                            baromzetlaag = baromzetlaag + statsDate.afhaalpunten[afhaalpunt].items[item].omzet - statsDate.afhaalpunten[afhaalpunt].items[item].promotie;
                        }						
                        if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.21){
                            baromzethoog = baromzethoog + statsDate.afhaalpunten[afhaalpunt].items[item].omzet - statsDate.afhaalpunten[afhaalpunt].items[item].promotie;
                        }
						if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.13){
                            baromzet13 = baromzet13 + statsDate.afhaalpunten[afhaalpunt].items[item].omzet - statsDate.afhaalpunten[afhaalpunt].items[item].promotie;
                        }
                    });
                });				
            }
            if(statsDate.tokenretour){
                inkomstenonline = inkomstenonline - statsDate.tokenretour.retourkosten;
            }
            if(statsDate.oplaadpunten){				
                Object.keys(statsDate.oplaadpunten).forEach(oplaadpunt => {
                    inkomstenoplaadpunten = inkomstenoplaadpunten + statsDate.oplaadpunten[oplaadpunt].omzet;
                })
            }
            if(statsDate.servicekosten){
                serviceKosten = serviceKosten + statsDate.servicekosten.partypayPaid;
            }	
		});		
		var consumptieoverschot = (inkomstenonline + inkomstenoplaadpunten) - (omzetlockers + baromzetlaag + baromzethoog + baromzet13);
		var datestr = '' + moment(new Date(startstr.replace(/-/g, ","))).format("DD-MM-YYYY") + ' t/m ' + moment(new Date(endstr.replace(/-/g, ","))).format("DD-MM-YYYY");
        var totaal = omzetlockers + baromzetlaag + baromzethoog + baromzet13 + consumptieoverschot + serviceKosten;
		var totaalExcl = (omzetlockers / 1.21) + (baromzetlaag / 1.09) + (baromzethoog / 1.21) + (baromzet13 / 1.13) + (serviceKosten / 1.21) + consumptieoverschot;
		var totaalBTW = ((omzetlockers * 21) / 121) + ((baromzetlaag * 9) / 109) + ((baromzethoog * 21) / 121) + ((baromzet13 * 13) / 113) + ((serviceKosten * 21) / 121);
		var statObj = [
			{"Omschrijving": "OMZET KLUISVERHUUR", "Periode": datestr, "BTW %": 0.21, "Omzet excl.": this.tls(omzetlockers / 1.21), "BTW": this.tls((omzetlockers * 21) / 121), "Omzet incl.": this.tls(omzetlockers)},
			{"Omschrijving": "OMZET BARREN LAAG", "Periode": datestr, "BTW %": 0.09, "Omzet excl.": this.tls(baromzetlaag / 1.09), "BTW": this.tls((baromzetlaag * 9) / 109), "Omzet incl.": this.tls(baromzetlaag)},
			{"Omschrijving": "OMZET BARREN HOOG", "Periode": datestr, "BTW %": 0.21, "Omzet excl.": this.tls(baromzethoog / 1.21), "BTW": this.tls((baromzethoog * 21) / 121), "Omzet incl.": this.tls(baromzethoog)},
			{"Omschrijving": "OMZET BARREN 13%", "Periode": datestr, "BTW %": 0.13, "Omzet excl.": this.tls(baromzet13 / 1.13), "BTW": this.tls((baromzet13 * 13) / 113), "Omzet incl.": this.tls(baromzet13)},
			{"Omschrijving": "TRANSACTIEKOSTEN", "Periode": datestr, "BTW %": 0.21, "Omzet excl.": this.tls(serviceKosten / 1.21), "BTW": this.tls((serviceKosten * 21) / 121), "Omzet incl.": this.tls(serviceKosten)},
			{"Omschrijving": "CONSUMPTIE OVERSCHOT", "Periode": datestr, "BTW %": 0, "Omzet excl.": this.tls(consumptieoverschot), "BTW": 0, "Omzet incl.": this.tls(consumptieoverschot)},
			{"Omschrijving": "TOTAAL", "Periode": datestr, "BTW %": "", "Omzet excl.": this.tls(totaalExcl), "BTW": this.tls(totaalBTW), "Omzet incl.": this.tls(totaal)}
		];
		if(type === 'xlsx'){this.generateXLSXDocument(statObj,info)}
		if(type === 'pdf'){this.generatePDFDocument(statObj,info,datestr)}
	}

	generateXLSXDocument = (statObj,info) => {
		var ws = XLSX.utils.json_to_sheet(statObj);
		var wb = XLSX.utils.book_new();
		ws['!cols'] = [{wch:30},{wch:30},{wch:10},{wch:20},{wch:20},{wch:20}];
		var adjustCells = ["D2","D3","D4","D5","D6","D7","E2","E3","E4","E5","E6","E7","F2","F3","F4","F5","F6","F7","C2","C3","C4","C5","C6"];
		adjustCells.forEach(c => {if(c.includes("C")){ws[c].z = '0.00%'} else{ws[c].z = '€ #,##0.00'}})
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, 'Partypay_Export_' + info.evt + '.xlsx');
	}

	generatePDFDocument = async (data,info,datestr) => {
		const blob = await pdf(<VerkoopPdf data={data} exportDate={datestr} exportInfo={info} />).toBlob();
		saveAs(blob, 'Partypay_Export_' + info.evt + '.pdf');
	};

    tls = (num) => {
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }


}

export default exportStats;



