import React from "react";
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import logo from "../../assets/images/pplogo.jpg";

function EventPdf(props) {
	return (
		<Document>			
			<Page style={{padding: '15px 50px 15px 50px', fontWeight: 700}}>
				<View style={{width: '100%', height: 50}} fixed></View>
				<View style={{paddingBottom: 50}}>
					<View style={{width: '100%', justifyContent: 'center'}}>
						<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
					</View>	
					<View style={{flexDirection: 'row', width: '100%', marginTop: 40, justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
						<View>
							<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai {props.name}</Text></View>
							<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.exportInfo.evt}</Text></View>	
						</View>	
						<View style={{alignItems: 'flex-end'}}>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.bedrijfsnaam}</Text></View>	
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.adres}</Text></View>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.postcodeplaats}</Text></View>
						</View>
					</View>		
				</View>				
				<View style={{flexDirection: 'row', color: '#555555', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Omschrijving</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>Omzet incl.</Text>
					</View>
				</View>			

				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Verkoop online</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.onlineomzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Verkoop cash/pin</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.offlineomzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Verkoop totaal</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {(props.data.onlineomzet + props.data.offlineomzet).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>


				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Bar omzet</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.baromzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Verschil verkoop/baromzet</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {((props.data.onlineomzet + props.data.offlineomzet) - props.data.baromzet).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{width: '100%', height: 25}} fixed></View>
			</Page>			
		</Document>
	);
}

export default EventPdf;